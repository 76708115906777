import React, { MouseEventHandler, ReactNode } from 'react'
import modalStyles from '@/styles/Modal.module.css'
import { Modal } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import WaitlistEntryModalTitle from './WaitlistEntryModalTitle'
import WaitlistEntryModalButtons from './WaitlistEntryModalButtons'
import WaitlistEntryModalCloseBtn from './WaitlistEntryModalCloseBtn'
import WaitlistEntryModalCompleteBtn from './WaitlistEntryModalCompleteBtn'

interface Props {
  id?: string
  icon: string
  title: string
  confirmProps?: {
    action: MouseEventHandler<HTMLButtonElement>
    text: string
    className?: string
    disabled?: boolean
  }
  handleCloseModal: MouseEventHandler
  children: ReactNode
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0px, -22px) scale(0.9)',
      zIndex: 0,
      color: '#333233',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    '& .MuiInputBase-root': {
      fontSize: '16px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    '& .MuiButtonGroup-root': {
      marginTop: theme.spacing(4),
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
    '& .MuiInput-input': {
      border: '1px solid #BCBCBC',
      padding: '12px 13px',
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#005BFF',
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      border: 'none',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
  },
  formControl: {
    marginTop: theme.spacing(4),
    width: '100%',
    '& .MuiInputBase-root': {
      height: '38px',
    },
    '& .MuiFormLabel-root': {
      transform: 'translate(15px, 12px)',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif !important',
    },
    '& .MuiInputLabel-animated.MuiInputLabel-shrink': {
      transform: 'translate(0px, -22px) scale(0.9)',
      zIndex: 0,
      color: '#333233',
    },
  },
  popover: {
    '& .MuiPaper-root.MuiPopover-paper': {
      top: '330px!important',
    },
  },
}))

export default function WaitlistEntryModal({
  id,
  icon,
  title,
  handleCloseModal,
  confirmProps,
  children,
}: Props) {
  return (
    <Modal
      show={true}
      onHide={handleCloseModal as () => void}
      id={id}
      centered
      dialogClassName={modalStyles.main}
    >
      <form className={classNames(modalStyles.form, useStyles().root)}>
        <div className="d-flex justify-content-end mb-2">
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={handleCloseModal}
          >
            <img src="/icons/close.svg" alt="close" />
          </button>
        </div>
        <WaitlistEntryModalTitle icon={icon} title={title} />

        {children}

        <WaitlistEntryModalButtons>
          <WaitlistEntryModalCloseBtn onClick={handleCloseModal}>
            Nevermind
          </WaitlistEntryModalCloseBtn>

          {confirmProps && (
            <WaitlistEntryModalCompleteBtn
              onClick={confirmProps.action}
              className={confirmProps.className}
            >
              {confirmProps.text}
            </WaitlistEntryModalCompleteBtn>
          )}
        </WaitlistEntryModalButtons>
      </form>
    </Modal>
  )
}
