import React, { useMemo } from 'react'
import typo from '@/styles/Typography.module.scss'
import buttonStyles from '@/styles/Buttons.module.scss'
import SlideHeading from '@/components/common/SlideHeading'
import {
  SlideContainer,
  SlideContent,
  SlideActionButtons,
} from '@/components/common/Slide'
import MultiselectField from '@/components/common/fields/MultiselectField'
import { Formik } from 'formik'
import InputField from '@/components/common/fields/InputField'
import TextareaField from '@/components/common/fields/TextareaField'
import { useDispatch } from 'react-redux'
import {
  resetFilters,
  setFilters,
  setIsFilterModalOpen,
} from '@/store/slices/pages/waitlistSlice'
import classNames from 'classnames'
import { activeStatuses, historicalStatuses } from '@/consts/FilterStatus'
import { useTypedSelector } from '@/store/index'
import usePartiesByLocation from '@/hooks/api-hooks/usePartiesByLocation'

export default function WaitlistFilterForm({ handleClose, customFields }) {
  const dispatch = useDispatch()
  const { mutateParties } = usePartiesByLocation()

  const { filters } = useTypedSelector((state) => state.pages.waitlist)

  // get all custom fields and set up the default redux waitlist filter status for them
  const customFieldsMap = useMemo(
    () =>
      customFields?.map((item) => ({
        ...item,
        options: item.options.map((option) => {
          return {
            ...option,
            label: option.value?.trim(),
            value: option.value?.trim(),
          }
        }),
      })),
    [customFields]
  )

  const cfFilters = useMemo(
    () =>
      customFieldsMap?.reduce(
        (accumulator, item) => ({
          ...accumulator,
          [item.id]: [],
        }),
        {}
      ),
    [customFieldsMap]
  )

  const formCfsFromFilters = useMemo(() => {
    return filters?.customFields?.reduce((acc, d) => {
      acc[d.id] = d.values
      return acc
    }, {})
  }, [filters])

  const formStatusFilter = useMemo(() => {
    return filters?.status ? filters?.status?.split(',') : []
  }, [filters])

  // build an array of options based on current view
  const availableStatusFilters = useMemo(
    () => (filters.isHistory ? historicalStatuses : activeStatuses),
    [filters.isHistory]
  )

  // build the formik values based on existing filters
  const initialFormikValues = {
    ...filters,
    status: formStatusFilter,
    customFields: {
      ...cfFilters,
      // put this last so we don't overwrite existing selections
      ...formCfsFromFilters,
    },
  }

  const cleanValuesForSubmission = (values) => {
    const { customFields, status } = values
    const cleanCfs = Object.keys(customFields)
      .filter((item) => {
        return customFields[item].length > 0
      })
      .map((item) => {
        return {
          id: item,
          values: customFields[item],
        }
      })
    return {
      ...values,
      customFields: cleanCfs,
      status: Array.isArray(status) ? status?.join(',') : status,
    }
  }

  // handle filter submission
  const onSubmit = async (values) => {
    const cleanedValues = cleanValuesForSubmission(values)
    dispatch(setFilters(cleanedValues))
    try {
      await mutateParties()
    } catch (e) {
      console.error(e)
    }

    handleClose()
  }

  return (
    <Formik initialValues={initialFormikValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <SlideContainer>
              <SlideContent>
                <SlideHeading
                  title="Filter"
                  src="/icons/filter-dark-feather-sliders.svg"
                />
                <div className="customs">
                  <h6 className={typo.h6}>By Status</h6>
                  <div>
                    <MultiselectField
                      options={availableStatusFilters}
                      name="status"
                      label="Select statuses"
                    />
                  </div>
                </div>
                {customFieldsMap?.length > 0 && (
                  <div className="mt-6">
                    <h6 className={typo.h6}>By Custom Field</h6>
                    {customFieldsMap.map((cf) => (
                      <div key={cf.id}>
                        {(cf.type === 'multiselect' ||
                          cf.type === 'select' ||
                          cf.type === 'radio' ||
                          cf.type === 'queueselect') && (
                          <MultiselectField
                            options={cf.options}
                            label={cf.name}
                            name={`customFields[${cf.id}]`}
                          />
                        )}

                        {(cf.type === 'tel' ||
                          cf.type === 'number' ||
                          cf.type === 'email') && (
                          <InputField
                            label={cf.name}
                            type={cf.type}
                            name={`customFields[${cf.id}]`}
                          />
                        )}
                        {cf.type === 'string' && (
                          <InputField
                            label={cf.name}
                            name={`customFields[${cf.id}]`}
                          />
                        )}
                        {cf.type === 'text' && (
                          <TextareaField
                            label={cf.name}
                            name={`customFields[${cf.id}]`}
                            fullWidth={true}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </SlideContent>
              <SlideActionButtons>
                <button
                  className={classNames(
                    buttonStyles.cardBtn,
                    buttonStyles.grayBtn,
                    'btn w-100'
                  )}
                  style={{ width: 'initial' }}
                  onClick={async () => {
                    dispatch(resetFilters())
                    await mutateParties()
                    handleClose()
                  }}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className={classNames(
                    buttonStyles.cardBtn,
                    buttonStyles.blueBtn,
                    'btn w-100'
                  )}
                  style={{ width: 'initial' }}
                  type="submit"
                >
                  Save
                </button>
              </SlideActionButtons>
            </SlideContainer>
          </form>
        )
      }}
    </Formik>
  )
}
