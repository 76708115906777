import axios from "axios"
import { Queue } from "@/api/interface/queue"
import { Location } from "../interface/location"
import { Business } from "../interface/business"

interface Params {
  filter?: {
    id?: string
    queueableId?: string
    queueableType?: "location" | "business"
    waitTimeAlg?: string
    name?: string
    visibility?: string
    trashed?: "with"
  }
  include: string
  sort: string
}

export const getQueuesByLocationId = (
  locationId: Location["id"]
): Promise<Array<Queue>> =>
  axios
    .get(`v2/queues`, {
      params: {
        sort: "index",
        filter: {
          queueableId: locationId,
          queueableType: "location",
        },
      } as Params,
    })
    .then((res) => res.data.data)

export const getQueuesByBusinessId = (
  businessId: Business["id"]
): Promise<Array<Queue>> =>
  axios
    .get(`v2/queues`, {
      params: {
        filter: {
          queueableId: businessId,
        },
        sort: "-name",
      } as Params,
    })
    .then((res) => res.data.data)

export const getQueues = (withTrashed?: boolean): Promise<Array<Queue>> => {

  return axios
  .get(`v2/queues`, {
    params: { 
      include: "locations", 
      sort: "index,queueable_type",
      filter: { 
        trashed: withTrashed ? 'with' : null,
      },
    } as Params,
  })
  .then((res) => res.data.data)
}
  

export const getQueue = (queueId: Queue["id"]): Promise<Queue> =>
  axios.get(`v2/queues/${queueId}`).then((res) => res.data.data)

export const createQueue = (queue: Partial<Queue>): Promise<Queue> =>
  axios.post(`v2/queues`, queue).then((res) => res.data.data) 

export const updateQueue = (queue: Partial<Queue>): Promise<Queue> =>
  axios.patch(`v2/queues/${queue.id}`, queue).then((res) => res.data.data)

export const updateQueueOrder = (
  queue: Partial<Queue> | string,
  position: number
) => {
  const id = typeof queue === 'string' ? queue : queue.id
  return axios.patch(`v2/queues/${id}/reposition/${position}`)
}

export const deleteQueue = (queueId: Queue["id"]): Promise<Queue> =>
  axios.delete(`v2/queues/${queueId}`).then((res) => res.data.data)

export const restoreQueue = (queueId: Queue["id"]): Promise<Queue> =>
  axios.patch(`v2/queues/${queueId}/restore`).then((res) => res.data.data)
