import styles from '@/styles/Pill.module.css'
import colors from '@/styles/Colors.module.scss'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useMobileOrTabletQuery } from '@/utils/responsive-queries'
import { BookingTimeRange } from '@/utils/bookings-utils'
import useBookingParties from '@/hooks/api-hooks/useBookingParties'
import { useTypedSelector } from '@/store/index'

interface BookingStatsProps {
  bookingTimeRange: BookingTimeRange
  count: number | string
}

export default function BookingStats({
  bookingTimeRange,
  count,
}: BookingStatsProps) {
  const isMobileOrTablet = useMobileOrTabletQuery()
  const { setSelectedBookingTimeRange, isBookingCountsLoading } =
    useBookingParties()
  const { bookingTimeRangeName } = useTypedSelector((state) => state.selected)

  const isActiveTab = useMemo(() => {
    return bookingTimeRange?.name === bookingTimeRangeName
  }, [bookingTimeRange, bookingTimeRangeName])

  return (
    <div
      className={classNames(isMobileOrTablet ? 'me-6' : 'me-4', 'mt-2 mt-lg-0')}
    >
      <button
        className={classNames(
          styles.pill,
          isActiveTab ? styles.active : null,
          'mb-2 me-0'
        )}
        onClick={() => {
          setSelectedBookingTimeRange(bookingTimeRange)
        }}
      >
        {bookingTimeRange?.label}
      </button>
      <div className="d-flex justify-content-center">
        <p
          className={classNames(colors.twilight, 'mb-0 font-semibold')}
          style={{ flex: 'none' }}
        >
          {isBookingCountsLoading || typeof count === 'string' ? (
            <div
              style={{
                width: '16px',
                height: '24px',
                backgroundColor: '#f4f4f4',
              }}
            ></div>
          ) : (
            <>{count === Infinity ? '25+' : count || 0}</>
          )}
        </p>
      </div>
    </div>
  )
}
