import React, { useMemo, useState } from 'react'
import WaitlistEntryModal from '@/components/pages/waitlist/WaitlistEntryModal/WaitlistEntryModal'
import buttonStyles from '@/styles/Buttons.module.scss'
import modalStyles from '@/styles/Modal.module.css'
import Select from '@/components/common/forms/Select'
import { useDispatch } from 'react-redux'
import { setAlert } from '@/store/slices/alertSlice'
import { printAxiosErrors } from '@/utils/util-functions'
import { Party } from '@/api/interface/party'
import { PartyRemovalOptions } from '@/consts/PartyStatusEnum'
import { checkIfDialCodeOnly } from '@/utils/formik-validators'
import useBookingParties from '@/hooks/api-hooks/useBookingParties'
import useBusiness from '@/hooks/api-hooks/useBusiness'

interface Props {
  actions: Record<string, Function>
  party: Party
  showPartyActionModal: string
  setShowPartyActionModal: Function
}

export interface UpdateActionOptions {
  status?: string
  alertMessage: string
  alertTitle: string
  [key: string]: any
}

export default function AllWaitlistEntryModals({
  actions,
  party,
  showPartyActionModal,
  setShowPartyActionModal,
}: Props) {
  const dispatch = useDispatch()
  const [removalReason, setRemovalReason] = useState('cancelled')
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const { mutateBookingCounts } = useBookingParties()
  const { business } = useBusiness()

  const canSendNotifications = useMemo(() => {
    return business?.currentSubscriptionPeriod?.messageCap ? business?.currentSubscriptionPeriod?.messageCount < business?.currentSubscriptionPeriod?.messageCap : true
  }, [business])

  const reasonOptions = Object.keys(PartyRemovalOptions).map((x) => ({
    label: PartyRemovalOptions[x],
    value: x,
  }))

  const handleCloseModal = (e) => {
    e && e.preventDefault()
    setShowPartyActionModal(null)
  }

  const cleanPartyForSubmission = (party: Party) => {
    const { guest } = party
    const partyToUpdate = { ...party }
    delete partyToUpdate?.bookingTime
    delete partyToUpdate?.isBooking

    const cleanGuest = {
      ...guest,
      phone: checkIfDialCodeOnly(guest?.phone) ? '' : guest?.phone,
    }
    return {
      ...partyToUpdate,
      guest: cleanGuest,
    }
  }

  const handleSubmit = async (
    e: MouseEvent,
    updateOptions: UpdateActionOptions
  ) => {
    e.preventDefault()

    if (isFormSubmitting) {
      return
    }

    try {
      setIsFormSubmitting(true)
      await actions.onSubmitUpdate(updateOptions)
      setIsFormSubmitting(false)
      setShowPartyActionModal(null)
      mutateBookingCounts()
    } catch (err) {
      console.error(printAxiosErrors(err))

      dispatch(
        setAlert({
          isOpen: true,
          title: err.response?.data?.message,
          type: 'error',
          message: printAxiosErrors(err, true),
        })
      )
      setIsFormSubmitting(false)
      setShowPartyActionModal(null)
    }
  }

  const serviceParty = (e) => {
    handleSubmit(e, {
      id: party?.id,
      status: 'in_service',
      alertMessage: `${party.guest.name} has been marked as in service.`,
      alertTitle: 'In Service',
    })
  }

  const completeParty = (e) => {
    handleSubmit(e, {
      id: party?.id,
      status: 'completed',
      alertMessage: `${party.guest.name} has been marked as completed.`,
      alertTitle: 'Completed',
    })
  }

  const removeParty = (e) => {
    handleSubmit(e, {
      ...cleanPartyForSubmission(party),
      status: removalReason,
      alertMessage: `${party.guest.name} has been removed from the waitlist.`,
      alertTitle: 'Removed',
    })
  }

  const notifyParty = (e) => {
    handleSubmit(e, {
      ...(party.guest.phone && { sendNotice: 'ready' }),
      id: party.id,
      status: 'notified',
      alertMessage:
        party.guest.phone && !checkIfDialCodeOnly(party.guest.phone)
          ? `An SMS has been sent to ${party.guest.name}.`
          : `${party.guest.name} has been marked as notified.`,
      alertTitle: 'Notified',
    })
  }

  const checkinParty = (e) => {
    //
    handleSubmit(e, {
      id: party?.id,
      status: 'checked_in',
      alertMessage: `${party.guest.name} has been marked as checked in.`,
      alertTitle: 'Checked In',
    })
  }

  const restoreParty = (e) => {
    //
    handleSubmit(e, {
      id: party?.id,
      status: 'waiting',
      alertMessage: `${party.guest.name} has been marked as waiting.`,
      alertTitle: 'Restored',
    })
  }

  return (
    <>
      {showPartyActionModal === 'service' && (
        <WaitlistEntryModal
          handleCloseModal={handleCloseModal}
          icon={'/icons/service-1.svg'}
          title="Service Guest"
          confirmProps={{
            action: serviceParty,
            text: 'Service',
            className: buttonStyles.blueBtn,
            disabled: isFormSubmitting,
          }}
        >
          <p>
            Please confirm you&apos;d like to service this guest. This will
            change their status to{' '}
            <span style={{ fontWeight: 'bold' }}>In Service</span> and move them
            to the History page.
          </p>
        </WaitlistEntryModal>
      )}
      {showPartyActionModal === 'complete' && (
        <WaitlistEntryModal
          handleCloseModal={handleCloseModal}
          icon={'/icons/service-1.svg'}
          title="Complete Service"
          confirmProps={{
            action: completeParty,
            text: 'Complete',
            className: buttonStyles.twilightBtn,
            disabled: isFormSubmitting,
          }}
        >
          <p>Please confirm that service for this guest is complete.</p>
        </WaitlistEntryModal>
      )}
      {showPartyActionModal === 'remove' && (
        <WaitlistEntryModal
          handleCloseModal={handleCloseModal}
          icon={'/icons/remove.svg'}
          title="Remove Guest"
          confirmProps={{
            action: removeParty,
            text: 'Remove',
            className: buttonStyles.redBtn,
            disabled: isFormSubmitting,
          }}
        >
          <p>
            Please select a reason for removing this guest from the waitlist.
          </p>

          <div className="mt-4">
            <Select
              label="Reason"
              className={modalStyles['reason-select']}
              onChange={(e) => {
                setRemovalReason(e.target.value)
              }}
              value={removalReason}
              options={reasonOptions}
            />
          </div>
        </WaitlistEntryModal>
      )}
      {showPartyActionModal === 'notify' && canSendNotifications && (
        <>
          {party.guest.phone && !checkIfDialCodeOnly(party.guest.phone) ? (
            <WaitlistEntryModal
              handleCloseModal={handleCloseModal}
              icon={'/icons/notify.svg'}
              title="Notify Guest"
              confirmProps={{
                action: notifyParty,
                text: 'Notify',
                className: buttonStyles.yellowBtn,
                disabled: isFormSubmitting,
              }}
            >
              <p>
                This will send them an SMS text message letting them know you
                are ready for them.
              </p>
            </WaitlistEntryModal>
          ) : (
            <WaitlistEntryModal
              handleCloseModal={handleCloseModal}
              icon={'/icons/phone-off.svg'}
              title="Notify In-Person"
              confirmProps={{
                action: notifyParty,
                text: 'Notify',
                className: buttonStyles.yellowBtn,
                disabled: isFormSubmitting,
              }}
            >
              <p>
                <span style={{ fontWeight: 600 }}>
                  There is no phone number for this guest.
                </span>{' '}
                Please notify them in person and then hit notify to update their
                status.
              </p>
            </WaitlistEntryModal>
          )}
        </>
      )}
      {showPartyActionModal === 'notify' && !canSendNotifications && (
        <WaitlistEntryModal
          handleCloseModal={handleCloseModal}
          icon={'/icons/alert-triangle-red.svg'}
          title="Message Cap Reached"
          confirmProps={{
            action: () => {
              window.location.href = `${window.location.origin}/business/subscription`
            },
            text: 'Upgrade',
            className: buttonStyles.twilightBtn,
            disabled: isFormSubmitting,
          }}
        >
          <p>
            You’ve used up all SMS messages included in your current plan level. Please upgrade your plan or wait until the start of your next billing period to send additional notifications to guests.
          </p>
        </WaitlistEntryModal>
      )}
      {showPartyActionModal === 'checkin' && (
        <WaitlistEntryModal
          handleCloseModal={handleCloseModal}
          icon={'/icons/map-pin-black.svg'}
          title="Check in Guest"
          confirmProps={{
            action: checkinParty,
            text: 'Check In',
            className: buttonStyles.greenBtn,
            disabled: isFormSubmitting,
          }}
        >
          <p>
            This will update the guest to a checked in status to indicate they
            have arrived.
          </p>
        </WaitlistEntryModal>
      )}
      {showPartyActionModal === 'restore' && (
        <WaitlistEntryModal
          handleCloseModal={handleCloseModal}
          icon={'/icons/corner-up-left-black.svg'}
          title="Restore Guest"
          confirmProps={{
            action: restoreParty,
            text: 'Restore',
            className: buttonStyles.blueBtn,
            disabled: isFormSubmitting,
          }}
        >
          <p>
            This will move your guest out of the history and back to your
            waitlist. The guest will be returned to a waiting status based on
            their original join time.
          </p>
        </WaitlistEntryModal>
      )}
    </>
  )
}
